<template>
  <div class="card">
    <div class="card-body">
      <h5 class="mb-0 text-center">
        {{ $t("widgets.workflowReporting.workflowReporting") }}
      </h5>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: [],
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>
